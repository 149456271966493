export const EMPTY_ERROR = 'EMPTY_ERROR'
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR'
export const FORBIDDEN_ERROR = 'FORBIDDEN_ERROR'
export const NOT_FOUND_ERROR = 'NOT_FOUND_ERROR'
export const UNPROCESSABLE_ENTITY_ERROR = 'UNPROCESSABLE_ENTITY_ERROR'
export const SERVER_ERROR = 'SERVER_ERROR'
export const INVALID_FORMAT_ERROR = 'INVALID_FORMAT_ERROR'
export const CONNECTION_ERROR = 'CONNECTION_ERROR'
export const TIMEOUT_ERROR = 'TIMEOUT_ERROR'
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR'
export const UN_PARKING_ERROR = 'UN_PARKING_ERROR'
export const ALREADY_BOOKED_ERROR = 'ALREADY_BOOKED_ERROR'
export const NUMBER_PLATE_NOT_IN_WHITELIST = 'NUMBER_PLATE_NOT_IN_WHITELIST'
export const UNAVAILABLE_UN_PARKING_UNTIL_CHECK_IS_DONE_ERROR = 'UNAVAILABLE_UN_PARKING_UNTIL_CHECK_IS_DONE_ERROR'
export const UNAVAILABLE_UN_PARKING_DUE_TO_ALREADY_UN_PARKED_ERROR = 'UNAVAILABLE_UN_PARKING_DUE_TO_ALREADY_UN_PARKED_ERROR'
export const UNAVAILABLE_UN_PARKING_DUE_TO_ALREADY_REFUNDED_ERROR = 'UNAVAILABLE_UN_PARKING_DUE_TO_ALREADY_REFUNDED_ERROR'
export const UNAVAILABLE_UN_PARKING_DUE_TO_TOO_EARLY_ATTEMPT_ERROR = 'UNAVAILABLE_UN_PARKING_DUE_TO_TOO_EARLY_ATTEMPT_ERROR'
export const UNAVAILABLE_PARKING_DUE_TO_SPECIAL_EVENT = 'UNAVAILABLE_PARKING_DUE_TO_SPECIAL_EVENT'
export const VEHICLE_WITH_NO_DGT_ENVIRONMENTAL_LABEL = 'VEHICLE_WITH_NO_DGT_ENVIRONMENTAL_LABEL'

export const OPENING_MODE_VIAT_EXIT = 5
export const OPENING_MODE_VIAT_ENTRY_EXIT = 4
